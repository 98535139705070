<template>
  <!-- 关键词 文章列表 -->
  <div class="container" style="padding-top:40px;">
    <!-- 导航 -->
    <V-ArticleNav class="headClass" :navList="navList" type="1"></V-ArticleNav>
    <div class="gWbc title" v-if="taskInfo">
      {{type=='1'?'录入':type=='2'?'校对':'审核'}}-{{taskInfo.taskName}}
    </div>
    <div class="gWbc basicInfo">
      <div class="ente_one">
        <div>{{showFileName(imgList[imgNum])}}</div>
        <div>著录</div>
      </div>
      <div class="ente_two">
        <div class="two_left">
          <div class="left_img_b">
            <div class="left_img" @mousewheel.prevent="handleScroll" ref="imgTop" @mouseleave.prevent="mouseLeave"
            @mousemove.prevent="mousemove">
              <img :src="api.LoginURL.concat('/resources/trading/upload'+imgList[imgNum])" ref="imgTitle" :class="{imgYd:mouseInDiv}"
              :style="{width:clientWidth/100*imgWidth+'px',top:top+'px',left:left+'px'}" v-if="imgList[imgNum]"
              @mousedown.prevent="getMousedowncoor" @mouseup.prevent="getMouseupcoor" @load="imgLoad"> 
            </div>
          </div>
          <div class="imgControl">
            <div class="jj_btn" @click="tapJJ('+')">+</div>
            <span class="jj_bum">{{imgWidth}}%</span>
            <div class="jj_btn" @click="tapJJ('-')">-</div>
            <div class="sx_btn" style="margin-left:80px" @click="tapImgNum(-1)">上一页</div>
            <div class="sx_btn" @click="tapImgNum(1)">下一页</div>
          </div>
          <div class="subtask">
            <div class="subtask_text">
              <div class="text1">子任务名称：</div>
              <div class="text2" style="text-decoration: underline;" v-if="taskInfo">{{taskInfo.childTaskName}}</div>
            </div>
            <!-- <div class="subtask_text">
              <div class="text1">子任务描述：</div>
              <div class="text2" v-if="taskInfo">{{taskInfo.childTaskDescribe}}</div>
            </div> -->
            <div class="subtask_text">
              <div class="text1">加工分页：</div>
              <div class="text2" v-if="taskInfo">{{taskInfo.childTaskPaging}}页</div>
            </div>
            <div class="subtask_text">
              <div class="text1">加工酬劳：</div>
              <div class="text2" v-if="taskInfo">
                {{taskInfo.childTaskMoney}}知识币
              </div>
            </div>
            <div class="subtask_text">
              <div class="text1">加工工期：</div>
              <div class="text2" v-if="taskInfo">{{taskInfo.childTaskDay}}天</div>
            </div>
            <!-- <div class="subtask_btn" v-if="true">
              ISLI资源关联
            </div>
            <div class="subtask_img" v-else>
            </div> -->
          </div>
        </div>
        <div class="two_right">
          <div class="recordItem" style="height:260px;overflow-y: auto;">
            <div v-for="(text,i) of recordInputList" :key="i">
              <p class="item_text">{{recordList[i]}}：</p>
              <el-input v-model="recordInputList[i]" :disabled='!isMayUpdate' class="right_input" placeholder="请输入"></el-input>
              <!--  @input="updateValue" -->
            </div>
          </div>
          <div class="two_right_text" v-if="isFullTextEntry==1">
            全文录入
          </div>
          <div class="recordItem" style="position: relative;" v-if="isFullTextEntry==1">
            <p class="item_text">全文录入：</p>
            <el-input type="textarea" v-model="typeInfo.fullText" resize="none" class="right_input"
             placeholder="请输入全文内容" :disabled='!isMayUpdate'></el-input>
             <!--  @input="updateValue" -->
            <span class="textStatistics">{{typeInfo.fullText.length}}字</span>
          </div>
          <div class="two_right_text">
            数字化加工
          </div>
          <div class="upFiles">
            <el-upload
              class="file_btns"
              :action="fileUrl"
              :headers="{token}"
              :data="{userName:loginName,type:'2'}"
              :show-file-list="false"
              :disabled='!isMayUpdate'
              :on-error="updateError"
              :on-success="updateSuccess"
              :before-upload="beforeAvatarUpload">
              <el-button size="small" type="primary" :class="{file_forbidden:!isMayUpdate}">
                上传文字录入、表格-Excel、图纸-CAD
              </el-button>
            </el-upload>
            <!-- <div class="file_btns">
              上传文字录入、表格-Excel、图纸-CAD
            </div> -->
            <div class="up_files">
              <div v-for="(text,i) of fileList" :key="i">
                <span>{{showFileName(text)}}</span>
                <span>
                  <span @click="tapDownload(text)" v-if="type!='1'" style="color:#0084FF;">下载</span>
                  <span @click="tapDetail(i)" style="margin-left:10px;" :class="{file_forbidden:!isMayUpdate}">删除</span>
                </span>
              </div>
              <span v-if="fileList.length<=0">上传附件为空</span>
            </div>
          </div>
        </div>
      </div>
      <div class="commit_btns">
        <el-button type="success"  @click="tapSave()">保存</el-button>
        <el-button type="danger" @click="isMayUpdate=true">修改</el-button>
        <el-button :type="imgList.length!=jgTypeInList.length?'info':'primary'" 
          @click="tapCommit" :style="imgList.length!=jgTypeInList.length?'cursor: no-drop':''">提交</el-button> 
      </div>
    </div>
  </div>
</template> 

<script>
import ArticleNav from "@/components/article-nav/index.vue";
import { QUERYED,UPDATES,INSERT,DELETE } from "@/services/dao.js";
import { Loading } from 'element-ui';
import api from "@/dev/api.ts";
export default {
  components: {
    "V-ArticleNav": ArticleNav
  }, 
  data() {
    return {
      navList:[  //导航 
        {url:'',word:''},
        {word:'录入'}
      ],

      //控制图片的默认值
      imgList:[],
      imgNum:0,  //图片下标
      clientWidth:1,  //图片初始宽度
      imgWidth:100,  //图片缩放百分比
      mouseInDiv:false,  //是否可拖动
      top:0,  //图片位移
      left:0, //图片位移
      clientX:0, //鼠标位置
      clientY:0,   //鼠标位置
      offsetX:0,  //鼠标距离容器顶部的距离
      offsetY:0,  //鼠标距离容器左边的距离   

      type:'1',   //1:录入  2.校验  3.审核
      isFullTextEntry:1,   //是否全文录入  1为是  2为否
      userid:'',  //用户id
      taskInfo:null,  //子任务信息
      recordList:[],  //著录列表
      recordInputList:[],  //著录列表输入

      //上传文件相关
      fileName:'',  //上传文件名
      token:'',
      loginName:'',  //登录名称
      // fileUrl:api.LoginURL.concat('/common/entryUpFile'),  //上传文件路径
      fileUrl:api.LoginURL.concat('/common/unzipFile'),  //上传文件路径
      fileList:[],    //文件列表

      jgTypeInList:[],  //所有子任务
      typeInfo:{
        id:'',
        jgPeperId:'',  //项目id
        childTaskId:'', //子任务id
        imgUrl:'',  //图片路径
        customEntryJson:'',  //自定义著录项
        fullText:'',   //全文录入
        fileList:''    //上传文件列表
      },
      // isUpdate:false,  //  是否修改
      isMayUpdate:false,  //是否可修改
    }
  },
  created(){
    let {id,type} = this.$route.query
    this.typeInfo.childTaskId = id
    let userid = this.$store.state.userinfo.id;   
    this.LoadingIcon = Loading.service()
    this.LoadingIcon.close()
    this.type = type?type:'1'
    if(!userid){
      this.$alert('请登录!', '提示', {
        confirmButtonText: '确定',
        callback: () => {
          this.$router.push({path:'/login'}) 
        }
      });
      return
    }
    console.log();
    this.token = localStorage.getItem('Authorization')
    this.userid = userid
    this.getUserInfo(userid)
    this.getData(id)
  },
  mounted(){
    this.$nextTick(()=>{
      this.clientWidth =  this.$refs.imgTop.clientWidth
    })
  },
  watch:{
    type(val){
      switch (val) {
        case '2':
          this.navList[1].word = '校对'
          break;
        case '3':
          this.navList[1].word = '审核'
          break;
        default: this.$set(this.navList[1],'word','录入')
          break;
      }
    }
  },
  methods: {
    //获取去用户信息
    async getUserInfo(id){
      let res = await QUERYED(
        "post","",
        "User(where:{id: {_eq:"+ id +"}}) { id name Money zsjg_prepay loginName }"
      );
      if(res.success){
        this.loginName  = res.data.User[0].loginName
      }
    },

    //获取子任务
    async getData(id){
      let res = await QUERYED(
        "post","",
        "jgChildTask(where:{id: {_eq:"+ id +"}}) {id taskType taskId taskName fzrId fzrName childTaskName  childTaskDescribe childTaskPaging childImgpath childTaskMoney childTaskDay jgUserId drawTime status }"
      );
      if(res.success){
        this.taskInfo = res.data.jgChildTask[0]
        let {childImgpath,childTaskPaging,taskId,taskName} =this.taskInfo
        this.navList[0].url="/taskDetail?id="+taskId+'&type=0'
        this.navList[0].word=taskName

        this.imgList = childImgpath?childImgpath.split(','):[]
        this.getCustomEntry(taskId)
        this.typeInfo.jgPeperId = taskId
        this.typeInfo.imgUrl = this.imgList[0]
        this.getJgTypeIn(taskId,id)


      }
    },

    //获取自定义著录项
    async getCustomEntry(id){
      let res = await QUERYED(
        "post","",
        "jgPeper(where:{id: {_eq:"+ id  +"}}) { isFullTextEntry customEntry }"
      );
      if(res.success){
        let list = res.data.jgPeper
        if(list.length<=0)return
        this.isFullTextEntry = list[0].isFullTextEntry
        this.recordList = list[0].customEntry?list[0].customEntry.split(','):[]
        for(let i in this.recordList){
          if(!this.recordInputList[i])this.recordInputList[i] = ''
        }
      }
    },

    //获取子任务图片对应的录入数据
    async getJgTypeIn(taskId,childTaskId){
      let res = await QUERYED(
        "post","",
        "jgTypeIn(where:{jgPeperId:{_eq:"+taskId+"},childTaskId: {_eq:"+ childTaskId  +"}}) { id jgPeperId childTaskId imgUrl customEntryJson fullText fileList }"
      );
      console.log(res,13);
      if(res.success){
        let list = res.data.jgTypeIn
        this.jgTypeInList = list
        for(let item of list){
          console.log(item.imgUrl==this.imgList[0],item.imgUrl,this.imgList[0]);
          if(item.imgUrl==this.imgList[0]){
            this.isMayUpdate = false
            this.typeInfo = JSON.parse(JSON.stringify(item))
            this.fileList = item.fileList?item.fileList.split(','):[]
            let arr = item.customEntryJson?item.customEntryJson.split(','):[]
            for(let num in arr){
              this.recordInputList[num] = arr[num].split(':')[1]
            }
            break
          }else{ 
            this.isMayUpdate = true
          }
        }
        if (list.length<=0) {
          this.isMayUpdate = true
        }
      }
    },

    //修改数据
    // updateValue(){
    //   this.isUpdate = true
    // },

    //警告弹窗
    msgWarn(message,type){
      this.$message({
        message,type: type||'warning'
      });
    },
    //判断值是否为空
    pdIsEmpty(e){
      return e.replace(/ /g,'')
    },

    //文件上传失败
    updateError(err){
      this.msgWarn('文件上传失败,请重试')
      this.LoadingIcon.close()
    },

    //上传前文件判断
    beforeAvatarUpload(file){
      let list = file.name?file.name.split('.'):[]
      let type = list[list.length-1].toLowerCase()
      let typeList = ['docx','dwg','xlsx','doc','xls']
      console.log(type,123,typeList.indexOf(type));
      if(typeList.indexOf(type)<0){
        this.msgWarn('请上传后缀为docx/xlsx/dwg格式的附件')
        return false
      }
      // if(type!='docx'&&type!='dwg'&&type!='xlxs'&&type!='doc'&&type!='xlx'){
      //   this.msgWarn('请上传后缀为docx/xlxs/dwg格式的附件')
      //   return false
      // }
      this.LoadingIcon = Loading.service()
      return true
    },

    //文件上传成功
    updateSuccess(response){
      this.LoadingIcon.close()
      if(!response.success){
        this.msgWarn(response.message)
      }else{
        // this.updateValue()
        this.fileList.push(response.data)
      }
    },

    //获取图片或文件名字
    showFileName(text){
      if(text){
        let list = text?text.split('/'):[]
        return list[list.length-1]
      }
    },

    //图片上一页、下一页
    tapImgNum(num){
      let imgMax = this.imgList.length-1
      let imgNum = this.imgNum
      imgNum += num*1
      if(imgNum<0){
        this.msgWarn('前面已经没有了')
        return        
      }
      if(imgNum>imgMax){
        this.msgWarn('后面已经没有了')
        return        
      }
      this.tapSave(imgNum)
      
    },

    //展示上一页或下一页数据
    showUpDownImg(imgNum){
      if(this.type!='1'){
        this.isMayUpdate=false
      }
      this.imgWidth = 100
      this.top = 0   //图片位移
      this.left = 0 //图片位移
      this.imgNum = imgNum
      let isData = true
      for(let item of this.jgTypeInList){
        if(item.imgUrl==this.imgList[imgNum]){
          this.isMayUpdate = false
          this.typeInfo = JSON.parse(JSON.stringify(item))
          this.fileList = item.fileList?item.fileList.split(','):[]
          let arr = item.customEntryJson?item.customEntryJson.split(','):[]
          for(let num in arr){
            this.recordInputList[num] = arr[num].split(':')[1]
          } 
          isData = false
          break
        }else{
          this.isMayUpdate = true
        }
      }
      if(this.jgTypeInList.length<=0)this.isMayUpdate = true
      if(isData){
        this.fileList = []
        this.typeInfo.id = ''
        this.typeInfo.fullText = ''
        this.typeInfo.imgUrl = this.imgList[imgNum]
        for(let i in this.recordInputList){
          this.recordInputList[i] = ''
        }
      }
    },
    
    
    //函数防抖
    // debounce(func, wait) {
    //   let timeout;
    //   return function () {
    //     let context = this;
    //     let args = arguments;
    //     if (timeout) clearTimeout(timeout);
    //     timeout = setTimeout(() => {
    //       func.apply(context, args)
    //     }, wait);
    //   }
    // },

    //图片加载时
    imgLoad(e){
      let h = this.$refs.imgTop.clientHeight
      let h1 = e.target.clientHeight
      if(h1<h){
        this.top = parseInt((h-h1)/2)
      }
    },

    //判断滚动方向对图片进行放大缩小
    handleScroll(e){
      let direction = 'down'
      if(e.wheelDelta){
        direction = e.wheelDelta>0? 'up' : 'down'
      }
      if(e.detail){
        direction = e.detail>0? 'up' : 'down'
      }
      if(direction == 'down'){
        this.imgWidth -=1
        if(this.imgWidth<30)this.imgWidth = 30
      }else{
        this.imgWidth +=1
        if(this.imgWidth>300)this.imgWidth = 300
      }
      // let {clientHeight,clientWidth} = this.$refs.imgTop
      // let h = this.$refs.imgTitle.clientHeight
      // let w = this.$refs.imgTitle.clientWidth
      // if(clientHeight<h){
      //   if(this.top>0)this.top=0
      //   if(this.top<(h-clientHeight))this.top = h-clientHeight
      // }
      // console.log('鼠标位置',this.offsetY,this.offsetX);
      // console.log('图片大小 ',h,w);  
      // console.log('容器大小',clientHeight,clientWidth);
      // this.top = parseInt((clientHeight/2)-this.offsetY)
      // this.top = parseInt((clientHeight-h)/2)-parseInt((clientHeight/2)-this.offsetY)
      // this.left = parseInt((clientWidth-w)/2)-parseInt((clientWidth/2)-this.offsetX)
      // console.log(this.top,this.left);     
    },

    // 鼠标按下
    getMousedowncoor(e) {
      this.mouseInDiv = true
      this.clientX = e.clientX
      this.clientY = e.clientY
    },
    //鼠标抬起
    getMouseupcoor() {
      this.mouseInDiv = false 
    },
    //鼠标移出
    mouseLeave(){
      this.mouseInDiv = false
    },
    // 鼠标移动
    mousemove(e){ 
      let {clientX,clientY} = e
      if(this.mouseInDiv){
        this.left += clientX - this.clientX
        this.clientX = clientX
        this.top += clientY - this.clientY
        this.clientY = clientY
        let {clientWidth,clientHeight} = this.$refs.imgTop
        let w = this.$refs.imgTitle.clientWidth
        let h = this.$refs.imgTitle.clientHeight
        if(w<=clientWidth){
          if(this.left<0)this.left = 0
          if(this.left>(clientWidth-w))this.left = clientWidth-w
        }else{
          if(this.left>0)this.left = 0
          if(this.left<(clientWidth-w))this.left = clientWidth-w
        }
        if(h<=clientHeight){   
          if(this.top<0)this.top = 0
          if(this.top>(clientHeight-h))this.top = clientHeight-h
        }else{
          if(this.top>0)this.top = 0
          if(this.top<(clientHeight-h))this.top = clientHeight-h
        }
      }
    },

    //点击按钮放大缩小图片
    tapJJ(val){
      if(val=='+'){
        this.imgWidth += 10
      }else{
        this.imgWidth -= 10
      }
      if(this.imgWidth<30)this.imgWidth = 30
      if(this.imgWidth>300)this.imgWidth = 300
    },

    //删除文件
    tapDetail(i){
      if(this.type!='1'&&!this.isMayUpdate)return
      this.$confirm('是否删除该文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.fileList.splice(i,1)
        // this.updateValue()
      }).catch(()=>{
        console.log('用户取消');
      })
    },

    //下载文件
    tapDownload(url){
      let url1 = api.LoginURL.concat('/resources/trading'+url)
      const link = document.createElement("a"); //创建a标签
      let arr = url1.split('/')
      link.download = arr[arr.length-1]; //a标签添加属性
      link.href = url1;
      link.click(); //执行下载
    },

    //点击保存时
    tapSave(imgNum){
      let {msgWarn,pdIsEmpty,typeInfo,fileList,recordList,recordInputList,jgTypeInList} = this
       //获取自定义注录项
      let customEntryJson = []
      for(let i in recordList){
        customEntryJson.push(recordList[i]+':'+recordInputList[i])
      }
      if(this.isFullTextEntry==1&&!this.pdIsEmpty(typeInfo.fullText)){
        msgWarn('全文录入不能为空')   
        return
      }
      typeInfo.customEntryJson = customEntryJson+''
      typeInfo.fileList = fileList+''
      if(!typeInfo.id){ 
        this.installData(typeInfo,imgNum)
      }else{
        this.updateData(typeInfo,imgNum)
      }
    },

    //点击保存时
    // tapSave(){
    //   let {msgWarn,pdIsEmpty,typeInfo,fileList,recordList,recordInputList,jgTypeInList} = this
    //    //获取自定义注录项
    //   let customEntryJson = []
    //   for(let i in recordList){
    //     customEntryJson.push(recordList[i]+':'+recordInputList[i])
    //   }
    //   if(this.isFullTextEntry==1&&!this.pdIsEmpty(typeInfo.fullText)){
    //     msgWarn('全文录入不能为空')   
    //     return
    //   }
    //   typeInfo.customEntryJson = customEntryJson+''
    //   typeInfo.fileList = fileList+''
    //   if(!typeInfo.id){ 
    //     this.installData(typeInfo)
    //   }else{
    //     this.updateData(typeInfo)
    //   }
    // },

    //新增数据
    async installData(typeInfo,imgNum){
      this.LoadingIcon = Loading.service()
      let value = ''
      for(let name in typeInfo){
        if(name!='id'){
          value += name
          if(typeof typeInfo[name] == 'string') value = value+':"'+typeInfo[name]+'",'
          else {
            value = value+':'+typeInfo[name]+','
          }
        }
      }
      value = value.slice(0,value.length-1)
      let installZSJG = await INSERT(
        "POST","",
        'insert_jgTypeIn(objects:{'+ value +'}) {affected_rows returning {id}}'
      )
      if(!installZSJG.success){ 
        this.msgWarn('保存失败，请重试')
      }else{
        this.typeInfo.id = installZSJG.data.insert_jgTypeIn.returning[0].id
        this.jgTypeInList.push(JSON.parse(JSON.stringify(this.typeInfo)))
        if(imgNum||imgNum===0){
          this.showUpDownImg(imgNum)
        }else{
          this.isMayUpdate = false
          this.msgWarn('保存成功','success')
        }
      }
      this.LoadingIcon.close()
    },

    //修改数据
    async updateData(typeInfo,imgNum){
      this.LoadingIcon = Loading.service()
      let {id,customEntryJson,imgUrl,fullText,fileList} = typeInfo
      let str = 'customEntryJson:"'+customEntryJson+'",fullText:"'+fullText+'",fileList:"'+fileList+'",imgUrl:"'+imgUrl+'"'
      //扣除知识币
      let upd = await UPDATES(
        "post","",
        "update_jgTypeIn(_set: {"+str+"}, where: {id: {_eq: "+id+"}}) {  affected_rows }"
      )
      if(!upd.success){ 
        this.msgWarn('保存失败，请重试')
      }else{
        for(let i in this.jgTypeInList){
          if(this.jgTypeInList[i].imgUrl==typeInfo.imgUrl){
            this.jgTypeInList[i] = JSON.parse(JSON.stringify(typeInfo))
            break
          } 
        }
        if(imgNum||imgNum===0){
          this.showUpDownImg(imgNum)
        }else{
          this.isMayUpdate = false
          this.msgWarn('保存成功','success')
        }
      }
      this.LoadingIcon.close()
    },

    //提交
    async tapCommit(){
      let {type,imgList,jgTypeInList,msgWarn} = this
      let num = 2
      if(imgList.length!=jgTypeInList.length){
        msgWarn('需要所有分页信息填写并保存后才可提交');
        return
      }
      switch (type) {
        case '1':num = 3
          break;
        case '2':num = 4
          break;
        case '3':num = 5
          break;
      }
      //录入完成提交
      if(imgList.length==jgTypeInList.length){
        let upd = await UPDATES(
          "post","",
          "update_jgChildTask(_set: {status:\""+num+"\"}, where: {id: {_eq: "+this.taskInfo.id+"}}) {  affected_rows }"
        )
        if(upd.success){
          if(type=='1'){
            this.type = '2'
            this.getData(this.taskInfo.id)
            msgWarn('子任务加工已完成，请仔细校对','success');
          }else{
            //返回上一个tab页面 返回上一步路由，
            if(type=='2'){
              msgWarn('子任务校对完成，已通知负责人进行审核','success');
              this.commitMsg()
            }
            if(type=='3'){
              msgWarn('子任务审核完成','success');
            }
            this.$router.go(-1);
          }
        }else{
          msgWarn('提交失败，请重试');
        }
      }else{
        msgWarn('您的录入未完成，无法提交');
      }
    },

    //校验完成后发送消息
    async commitMsg(){
      let {id,taskId,taskName,fzrId,fzrName,childTaskMoney} = this.taskInfo
      let taskTime = new Date()*1
      let value = "messageType:1,taskTime:"+taskTime+",taskName:\""+taskName+"\",taskType:4,taskId:\""+id+"\",taskFatherId:"
      +taskId+",userId:"+fzrId+",userType:2,money:"+childTaskMoney+",fzrName:\""+fzrName+"\",taskTypeTow:0"
      await INSERT(
        "POST","",
        'insert_jgMessage(objects:{'+ value +'}) {affected_rows returning {id}}'
      )
    }
  }
};
</script>

<style scoped>
.container {
  /* text-align: center; */
  background-color: #EEEEEE;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headClass {
  width: 1090px;
  height: 66px;
  line-height: 66px;
  text-align: left;
}
.gWbc{
  width: 1090px;
  background-color: #fff;
}
.title{
  height: 72px;
  line-height: 72px;
  text-align: center;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 3px solid #eee;
}
.basicInfo{
  padding:0 80px;
  padding-bottom: 80px;
  box-sizing: border-box;
}
.ente_one{
  width: 100%;
  height: 66px;
  display: flex;
  border:2px solid #eee;
  border-top: 0;
  box-sizing: border-box;
}
.ente_one>div{
  width: 50%;
  padding:0 20px;
  text-align: center;
  line-height: 66px;
  font-size: 14px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ente_one>div:last-child{
  font-size: 18px;
  border-left: 2px solid #eee;
}
.ente_two{
  width: 100%;
  border-left:2px solid #eee;
  display: flex;
  box-sizing: border-box;
}
.two_left{
  width: 50%;
}
.two_right{
  width: 50%;
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
  display: flex;
  flex-direction: column;
}
.left_img_b{
  width: 100%;
  height: 500px;
  padding:10px;
  border-bottom: 2px solid #eee;
  box-sizing: border-box;
}
.left_img{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.left_img>img{
  position: relative;
}
img.imgYd{
  cursor: move;
}
.imgControl{
  width: 100%;
  height: 66px;
  border-bottom:2px solid #eee;
  display: flex;
  align-items: center;
}
.jj_btn{
  width: 22px;
  height: 22px;
  margin-left: 28px;
  border:2px solid #ddd;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
  font-size: 18px;
  cursor: pointer;
}
.jj_bum{
  width: 50px;
  text-align: center;
  margin-left: 20px;
  font-size: 16px;
  cursor: pointer;
}
.sx_btn{
  width: 78px;
  height: 34px;
  margin-left: 23px;
  background-color: #EEEEEE;
  border: 1px solid #B5B5B5;
  border-radius: 5px;
  text-align: center;
  line-height: 34px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  cursor: pointer;
}
.sx_btn:hover{
  opacity: 0.8;
}
.subtask{
  width: 100%;
  height: 349px;
  padding:24px;
  box-sizing: border-box;
  border-bottom:2px solid #eee;
  overflow-y: auto;
  position: relative;
}
.subtask_text{
  padding:9px 0;
  display: flex;
}
.subtask_btn{
  width: 410px;
  height: 60px;
  color: #fff;
  background-color: #409eff;
  text-align: center;
  line-height: 60px;
  position: absolute;
  bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}
.subtask_btn:hover{
  opacity: 0.9;
}
.subtask_img{
  width: 150px;
  height: 150px;
  background-color: #eee;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.text1{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
.text2{
  flex: 1;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.recordItem{
  width: 100%;
  padding:30px;
  flex: 1;
  box-sizing: border-box;
  /* border-right: 2px solid #eee; */
  border-bottom: 2px solid #eee;
}
.item_text{
  font-size: 14px;
  color: #000;
  font-weight: bold;
}
.two_right_text{
  width: 100%;
  height: 66px;
  /* border-right: 2px solid #eee; */
  border-bottom: 2px solid #eee;
  text-align: center;
  line-height: 66px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  box-sizing: border-box;
}
.right_input>>>.el-textarea__inner{
  height: 200px;
}
.textStatistics{
  position: absolute;
  font-size: 12px;
  color: #999;
  bottom: 10px;
  right: 40px;
}
.upFiles{
  height: 243px;
  padding:7px 5px;
  flex: 1;
  /* border-right: 2px solid #eee; */
  /* border-bottom: 2px solid #eee; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.file_btns{
  height: 40px;
  border-radius: 8px;
}
.file_btns>>>.el-upload{
  width: 100%;
  height: 100%;
}
.file_btns>>>.el-button{
  width: 100%;
  height: 100%;
  background-color: #EEEEEE;
  border: 1px solid #B5B5B5;
  line-height: 40px;
  text-align: center;
  padding:0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000;
  cursor: pointer;
}
.file_btns:hover{
  opacity: 0.9;
}
.file_forbidden{
  cursor: not-allowed !important;
}
.up_files{
  flex: 1;
  /* height:170px; */
  margin-top: 20px;
  overflow-y: auto;
}
.up_files>div{
  padding:0 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.up_files>div>span:first-child{
  width: 350px;
}
.up_files>div>span:last-child{
  font-size: 12px;
  color: #999;
  cursor: pointer;
}
.up_files>div+div{
  margin-top: 20px;
}
.commit_btns{
  padding:90px 70px;
  padding-bottom: 0;
  display: flex;
  justify-content:center;
}
.commit_btns>>>.el-button{
  width: 130px;
  
}
</style>
